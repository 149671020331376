import React from "react";




export const Jumbo = () => {
    return (
        <div className="md:flex md:flex-row flex flex-col items-center">
            <img className="md:mr-10 md:w-[10%] md:max-w-none md:h-auto w-44 h-44" src="http://www.inbravisa.com.br/imagens/logo%20INBRAVISA_FB%20(13).jpg"></img>
            <br />
            <p className="flex text-xl align-middle text-justify" >
                Agradecemos sua visita ao nosso web site!
                O INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária é uma entidade privada, fundada em 2003, e que presta serviços na área de regulação sanitária
                Presidido pelo Dr. Rui Dammenhain, especialista em vigilancia sanitária, que durante 08 anos ocupou cargos de direção junto ao Centro de Vigilância Sanitária (CVS/SP), e com uma equipe altamente qualificada, oferece os mais variados serviços na área de regulação. Toda a equipe do INBRAVISA atende aos requisitos que a Agência Nacional de Vigilância Sanitária exige para consultores
                Criou recentemente os Protocolos Técnicos Sanitários, que associam videos aula, mentorias de apoio, material didático exclusivo e certificado de participação,  com a intenção de promover conhecimento aos mais diversos segmentos abrangidos pela legislação sanitária.
            </p>
            <p>

            </p>
        </div>
    )
}